<template>
  <moe-page title="新增助力拉新">
    <moe-form
      class="default-form"
      ref="assistForm"
      :showBack="false"
      :showClose="true"
      :model="assistParams"
      :rules="rules">
      <div style="width: 50%;" class="margin-0-auto">
        <div class="fwb mb-20 font-20">基本信息</div>
        <el-form-item label="活动名称" prop="name" label-width="150px">
          <el-input v-model.trim="assistParams.name" placeholder="请输入活动名称" clearable maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort" label-width="150px">
          <el-input :value="assistParams.sort" @input="(value) => assistParams.sort = $moe_formatter.formatterSort(value)" placeholder="请输入排序，数值越大排序越靠前" maxlength="50" clearable />
        </el-form-item>
        <el-form-item label="活动时间" prop="startTime" label-width="150px">
          <el-date-picker
            :disabled="IS_EDIT"
            style="width: 100%;"
            placement="bottom-start"
            v-model="datetime"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            @change="(datetime) => ([assistParams.startTime, assistParams.endTime] = datetime || ['', ''])" />
        </el-form-item>
        <el-form-item label="助力时间" prop="deadline" label-width="150px">
          <el-input :disabled="IS_EDIT" class="w-300" :value="assistParams.deadline" @input="(value) => assistParams.deadline = $moe_formatter.formatterInteger(value)" placeholder="请输入助力时间" maxlength="50" clearable>
            <template slot="append">小时</template>
          </el-input>
        </el-form-item>
      </div>

      <!-- <el-form-item label="优惠券" prop="couponId" label-width="150px">
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="showDialog = true;">选择优惠券</el-button>
      </el-form-item> -->
      <div class="mb-30 margin-0-auto" style="width: 70%;">
        <moe-table
          :stripe="true"
          :numberShow="false"
          :data="assistParams.couponId"
          :mode="false"
          :showPage="false"
          :params="{ pageNum: 1 }"
          emptyText="请添加优惠券"
          rowKey="goodsId">
          <template slot="tool">
            <el-form-item prop="couponId" :rules="rules.couponId">
              <el-button type="primary" icon="el-icon-circle-plus-outline" @click="showDialog = true;">添加优惠券</el-button>
            </el-form-item>
          </template>

          <el-table-column label="活动信息" min-width="150">
            <template slot-scope="{ row }">
              <div>{{ row.name }}</div>
              <div>券编号:{{ row.id }}</div>
            </template>
          </el-table-column>

          <el-table-column label="优惠方式" min-width="150">
            <template slot-scope="{ row }">
              {{ `满 ${row.condition} 元 减 ${row.discount} 元` }}
            </template>
          </el-table-column>

          <el-table-column label="使用时间" min-width="310">
            <div class="df fdc aic jcc" slot-scope="{ row }">
              <template v-if="row.timeType === 'TIME_RANGE'">
                <div>{{ $moe_time.getTimeStamp(row.startTime) }} 至 {{ $moe_time.getTimeStamp(row.endTime) }}</div>
                <div>活动持续：{{ $moe_time.countDown(row.endTime, row.startTime) }}</div>
              </template>
              <div v-if="row.timeType === 'RELATIVE'">{{ `领取后 ${row.effectiveDays} 天有效` }}</div>
            </div>
          </el-table-column>

          <el-table-column label="操作" width="200" fixed="right">
            <template slot-scope="{ row, $index }">
              <el-button :disabled="IS_EDIT" icon="el-icon-delete" size="small" type="danger" @click="handleDelete(row, $index)">移除</el-button>
            </template>
          </el-table-column>
        </moe-table>
      </div>

      <div style="width: 50%;" class="margin-0-auto">
        <div class="fwb mb-20 font-20">规则设置</div>

        <el-form-item label="助力人数" prop="num" label-width="150px">
          <el-input :disabled="IS_EDIT" class="w-300" :value="assistParams.num" @input="(value) => assistParams.num = $moe_formatter.formatterInteger(value)" placeholder="请输入助力人数" maxlength="50" clearable />
        </el-form-item>

        <el-form-item label="助力对象" prop="target" label-width="150px">
          <div>
            <el-radio :disabled="IS_EDIT" v-model="assistParams.target" label="NEW">指定新人(注册时间晚于活动开始时间，新人限定助力一次，适用于所有新人助力活动)</el-radio>
          </div>
          <div>
            <el-radio :disabled="IS_EDIT" v-model="assistParams.target" label="ALL">全部用户(每人每天限定助力一次)</el-radio>
          </div>
        </el-form-item>

        <el-form-item label="奖励限制" prop="maximum" label-width="150px">
          <el-input class="w-500" :value="assistParams.maximum" @input="(value) => assistParams.maximum = $moe_formatter.formatterInteger(value)" placeholder="请输入奖励限制次数" maxlength="50" clearable>
            <template slot="prepend">限制活动期限内每人最多获得奖品</template>
            <template slot="append">次</template>
          </el-input>
          <el-tooltip class="ml-10" content='1.此限制为助力成功次数不可多于X次。超出提示"您发起的助力活动已达上限。" 2.不填不限制。' placement="top">
            <moe-icon name="reminder" size="24px"></moe-icon>
          </el-tooltip>
        </el-form-item>
      </div>

      <template slot="tool">
        <el-button type="primary" icon="el-icon-upload2" :loading="assistLoad" @click="handleSubmit()">{{ assistLoad ? '保存中' : '提交' }}</el-button>
      </template>
    </moe-form>

    <couponDialog
      :showDialog.sync="showDialog"
      :defaultSelectIds="assistParams.couponId && assistParams.couponId.length ? assistParams.couponId.map(({ id }) => id) : []"
      :multiple="false"
      @close="handleClose">
    </couponDialog>
  </moe-page>
</template>

<script>
import couponDialog from '@/views/ActivityManage/components/couponDialog.vue';
export default {
  name: 'ActivityManageAssistAdd',
  components: {
    couponDialog
  },
  computed: {
    IS_EDIT() {
      if (this.assistParams.id) {
        return true;
      } else {
        return false;
      }
    }
  },
  data() {
    const checkNum = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(`请输入助力人数`));
      } else {
        if (this.$moe_math.mathGreaterThan(value, 10)) {
          return callback(new Error(`助力人数最多"10"个人`));
        } else {
          callback();
        }
      }
    }
    const verify = this.$moe_verify.verifyForm;
    return {
      assistParams: {
        id: '',
        name: '',
        sort: '',
        startTime: '',
        endTime: '',
        deadline: '',
        couponId: [],
        num: '',
        target: 'NEW',
        maximum: '',
      },
      datetime: [],
      rules: {
        name: verify.isEmpty('请输入活动名称'),
        startTime: verify.isEmpty('请选择活动时间'),
        deadline: verify.isEmpty('请输入助力时间'),
        couponId: verify.isEmpty('请添加优惠券'),
        // num: verify.isEmpty('请输入助力人数'),
        num: [{ required: true, validator: checkNum, trigger: ['blur', 'change'] }],
        target: verify.isEmpty('请选择助力对象'),
        // maximum: verify.isEmpty('请输入奖励限制次数'),
      },
      assistLoad: false,
      showDialog: false,
    }
  },
  methods: {
    /** 添加优惠券 确定 */
    handleClose(selectList) {
      if (this.assistParams.couponId.length) {
        return this.$moe_msg.warning('最多添加一张优惠券!');
      }
      this.assistParams.couponId = selectList;
      this.$refs['assistForm'].validateField('couponId');
      this.showDialog = false;
    },
    handleDelete({ name }, index) {
      this.$moe_layer.confirm(`您确定要撤出当前选项"${name}”吗？`, () => {
        this.assistParams.couponId.splice(index, 1);
      });
    },
    handleSubmit() {
      this.$refs['assistForm'].validate(() => {
        this.assistLoad = true;
        let params = this.$moe_lodash.cloneDeepData(this.assistParams);
        params.couponId = params.couponId.map(({ id }) => id).join(',');
        params.sort = params.sort || 0;
        params.maximum = params.maximum || null;
        if (this.IS_EDIT) {
          this.req_updateAssist(params);
        } else {
          this.req_addAssist(params);
        }
      })
    },
    /** 修改助力拉新活动 */
    req_updateAssist(params) {
      this.$moe_api.ASSIST_API.updateAssist(params).then((data) => {
        if (data.code === 200) {
          this.$moe_msg.success('编辑助力拉新成功', {
            completionHandler: () => {
              this.$moe_coordinator.navigateBack();
            }
          });
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 创建助力拉新活动 */
    req_addAssist(params) {
      this.$moe_api.ASSIST_API.addAssist(params).then((data) => {
        if (data.code === 200) {
          this.$moe_msg.success('新增助力拉新成功', {
            completionHandler: () => {
              this.$moe_coordinator.navigateBack();
            }
          });
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 获取助力拉新活动详情 */
    req_getAssistDetail() {
      if (this.$route.query.id) {
        this.$moe_api.ASSIST_API.getAssistDetail({ id: this.$route.query.id }).then((data) => {
          if (data.code == 200) {
            let { id, name, sort, startTime, endTime, deadline, couponId, num, target, maximum } = data.result;
            this.assistParams = {
              id,
              name,
              sort,
              startTime,
              endTime,
              deadline,
              couponId: [],
              num,
              target,
              maximum: maximum || '',
            }
            if (startTime) {
              this.datetime = [startTime, endTime];
            }

            if (couponId) {
              this.req_getCouponDetail(couponId);
            }
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      }
    },
    /** 获取优惠券详情 */
    req_getCouponDetail(id) {
      this.$moe_api.COUPON_API.getCouponDetail({ id }).then((data) => {
        if (data.code === 200) {
          this.assistParams.couponId = [data.result];
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    }
  },
  mounted() {
    this.req_getAssistDetail();
  }
}
</script>

<style lang="scss">

</style>